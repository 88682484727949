<template>
	<div class="home mobile">
		<!-- 第一个模块：全屏背景图 -->
		<div class="header">
			<div class="logo-row">
				<img src="@/assets/logo.png" alt="logo"> 
			</div>

			<div class="hamburger" id="bars" ref="hamburger" @click="dropFunc()">
				<span class="bar"></span>
				<span class="bar"></span>
				<span class="bar"></span>
			</div>
		</div>
		<!-- 下拉菜单 -->
		<transition name="dropdown-fade-show">
			<div ref="dropdown" v-show="dropDownShow" @touchmove.prevent class="dropdown" id="dropdown">
				<div class="drop-content">
					<div class="product-list">
						<div class="product-item">
							<a href="#">关于我们</a>
						</div>
					</div>
					<div class="product-list">
						<div class="product-item">
							<a href="mailto:contact@synjoy.cn">联系我们</a>
						</div>
					</div>
					<div class="product-list">
						<div class="product-item">
							<a href="mailto:opportunity@synjoy.cn">加入我们</a>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<div class="fullscreen-background">
			<picture class="module-img">
				<source srcset="@/assets/build.webp" type="image/webp"> <!-- 如果浏览器支持 WebP，将使用此源 -->
				<source srcset="@/assets/build.png" type="image/png"> <!-- 如果不支持 WebP，将回退到 PNG -->
				<img src="@/assets/build.png" alt="build"> <!-- 如果上述两个源都不可用，将显示此图片 -->
			</picture>
			<!-- 可以放置一些内容或标题 -->
			<h1 class="module-1-title" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500">
				<span>使命</span>
				为全球用户提供卓越的移动互联网体验 <br>
				让数字内容被平等获取
			</h1>

			<div class="synjoy-row">
				<div class="synjoy s" data-aos="fade-left" data-aos-delay="0" data-aos-duration="1200">
					<img src="@/assets/S.png" alt="">
				</div>
				<div class="synjoy y1" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1200">
					<img src="@/assets/Y.png" alt="">
				</div>
				<div class="synjoy n" data-aos="fade-left" data-aos-delay="400" data-aos-duration="1200">
					<img src="@/assets/N.png" alt="">
				</div>
				<div class="synjoy j" data-aos="fade-left" data-aos-delay="600" data-aos-duration="1200">
					<img src="@/assets/J.png" alt="">
				</div>
				<div class="synjoy o" data-aos="fade-left" data-aos-delay="800" data-aos-duration="1200">
					<img src="@/assets/0.png" alt="">
				</div>
				<div class="synjoy y2" data-aos="fade-left" data-aos-delay="1000" data-aos-duration="1200">
					<img src="@/assets/Y.png" alt="">
				</div>
			</div>

		</div>

		<div class="normal-module module-2">
			<div class="company-info">
				深聚科技（SYNJOY）自2023年创立以来，已迅速发展成为一家以产品技术为核心竞争力的全球化移动互联网先锋企业。我们不仅是Google、Meta、ByteDance（字节跳动）的全球业务合作伙伴，也是中国互联网国际化进程中的一支新锐力量。
			</div>

			<div class="company-adver-row">
				<div class="company-adver-item">
					<div class="company-adver-graphy" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-offset="0">
						<picture>
							<source srcset="@/assets/graphy-1.webp" type="image/webp"> <!-- 如果浏览器支持 WebP，将使用此源 -->
							<source srcset="@/assets/graphy-1.png" type="image/png"> <!-- 如果不支持 WebP，将回退到 PNG -->
							<img src="@/assets/graphy-1.png" alt="graphy-1"> <!-- 如果上述两个源都不可用，将显示此图片 -->
						</picture>
					</div>
					<div class="company-adver-item-info-row" data-aos="fade-up" data-aos-duration="500">
						<div class="company-adver-title">
							全球市场
						</div>
						<div class="company-adver-content">
							我们的业务遍及包括欧美、东南亚、中东和非洲在内的200多个国家和地区，覆盖了“一带一路”沿线的全部65个国家。作为中国互联网出海的新秀，深聚科技的产品已服务超过1亿用户，使得越来越多的人通过我们的技术接触到移动互联网，让他们的生活更加便捷和有趣。
						</div>
					</div>
				</div>
				<div class="company-adver-item">
					<div class="company-adver-graphy" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-offset="0">
						<picture>
							<source srcset="@/assets/graphy-2.webp" type="image/webp"> <!-- 如果浏览器支持 WebP，将使用此源 -->
							<source srcset="@/assets/graphy-2.png" type="image/png"> <!-- 如果不支持 WebP，将回退到 PNG -->
							<img src="@/assets/graphy-2.png" alt="graphy-2"> <!-- 如果上述两个源都不可用，将显示此图片 -->
						</picture>
					</div>
					<div class="company-adver-item-info-row">
						<div class="company-adver-title">
							业务
						</div>
						<div class="company-adver-content">
							公司总部位于闻名遐迩的“中国硅谷”——中关村上地，这里不仅是技术创新的热土，也是我们企业精神的发源地。我们将移动应用的研发、运营和发行紧密结合，形成了一条高效且创新的产业链。在这个过程中，我们包括社交互动、休闲游戏、AI陪伴等多款产品逐步赢得了市场认可，并在众多竞争中脱颖而出。
						</div>
					</div>

				</div>
			</div>

		</div>

		<div class="normal-module module-3 ">
			<div class="mission-row">
				<div class="mission-item r">
					愿景
					<div class="mission-item-head">
						成为连接中国企业与世界市场的桥梁 <br>
						共同塑造一个紧密相连的全球商业生态
					</div>
				</div>
			</div>
		</div>

		<!-- 后续模块 -->
		<div class="normal-module module-4 ">

			<swiper class="swiper" :options="swiperOption">
				<swiper-slide :style="backgroundStyle(1)"></swiper-slide>
				<swiper-slide :style="backgroundStyle(2)"></swiper-slide>
				<swiper-slide :style="backgroundStyle(3)"></swiper-slide>
			</swiper>

			<div class="overlay"></div>

			<div class="our-team-row">
				<div class="our-team-title">
					我们的团队
				</div>
				<div class="our-team-content">
					我们深知，一流的团队是打造一流产品的关键。深聚科技的团队核心成员毕业于清华大学、北京大学、东北大学、哈尔滨工业大学、美国卡内基梅隆大学、澳大利亚墨尔本大学、香港理工大学等国内外优秀院校；管理层则由来自谷歌、百度、美国Zynga、字节跳动等行业领军企业的资深人士组成。这样的团队组合确保了我们在技术和创新方面的领先地位，为公司的持续发展提供了坚实的基础。
				</div>
				<div class="our-team-logo-row">
					<div class="team-logo-item" v-for="i in 8">
						<img :src="require(`@/assets/t-${i}.png`)" alt="">
					</div>
				</div>
			</div>
		</div>

		<div class="normal-module module-5">

			<div class="vision-row">
				<div class="vision-title">
					企业价值观
				</div>
				<div class="vision-content">
					深聚科技坚持的核心价值观是“实事求是”。这一简单而纯粹的原则指导我们应对创业路上的种种挑战。我们深信只有坚持真实、客观的态度，知行合一，才能敏锐地捕捉并抓住机遇，实现从局部单点突破到整体成功的跨越。这样的价值观指引着我们灵活应对各种情况、稳步实现目标。
				</div>
			</div>

			<div class="circle-row">
				<div class="circle circle-1">
					<div class="circle-content">
						<div class="circle-c">
							实事求是
						</div>
						<div class="circle-t">
							Seek truth from facts
						</div>
					</div>
				</div>
				<div class="circle circle-2">
					<div class="circle-content">
						<div class="circle-c">
							知行合一
						</div>
						<div class="circle-t">
							Unity of knowledge and action
						</div>
					</div>
				</div>
				<div class="circle circle-3"></div>
				<div class="circle circle-4">
					<div class="circle-content">
						<div class="circle-c">
							坚持真实
						</div>
						<div class="circle-t">
							Keep it real
						</div>
					</div>
				</div>
				<div class="circle circle-5">
					<div class="circle-content">
						<div class="circle-c">
							客观的态度
						</div>
						<div class="circle-t">
							Objective attitude
						</div>
					</div>
				</div>
			</div>

		</div>

		<footer class="normal-module module-6">
			<div class="footer-content-row">
				<div class="call-us-row">
					<div class="call-us-head">
						我们很乐意听取您的意见!
					</div>
					<div class="call-us-item">
						您可以通过以下渠道联系我们:
					</div>
					<div class="call-us-item large">
						<a href="mailto:contact@synjoy.cn">contact@synjoy.cn</a>
					</div>
				</div>
			</div>

			<div class="dash-line"></div>
			<div class="copyright">
				synjoy.cn | 北京深聚科技有限公司
			</div>
			<div class="copyright">
				<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">京ICP备 2024043778 号</a> | <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802043952" target="_blank" rel="noopener noreferrer">京公网安备 11010802043952 号</a>
			</div>
			

		</footer>

	</div>
</template>

<script>
import "@/css/pc.scss";
import "@/css/mobile.scss";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { lock, unlock } from "tua-body-scroll-lock";

export default {
	name: "pc",
	data() {
		return {
			dropDownShow: false,
			swiperOption: {
				autoHeight: true, // 高度自适应
				loop: true,
				autoplay: {
					delay: 3000, // 自动切换的时间间隔，单位为毫秒
					disableOnInteraction: false, // 用户操作后是否停止自动播放
				},
			},
		};
	},
	components: {
		Swiper,
		SwiperSlide,
	},
	methods: {
		dropFunc() {
			this.dropDownShow = !this.dropDownShow;
			if (this.dropDownShow) {
				lock();
				// 添加全局点击事件监听器
				setTimeout(
					() =>
						document.addEventListener(
							"click",
							this.handleClickOutside
						),
					0
				);
			}
			if (!this.dropDownShow) {
				unlock();
				// 移除监听器
				document.removeEventListener("click", this.handleClickOutside);
			}
		},
		handleClickOutside(event) {
			const dropdownElement = this.$refs.dropdown;
			const hamburgerElement = this.$refs.hamburger;
			// 检查点击是否在下拉菜单或汉堡菜单按钮外部
			if (
				dropdownElement &&
				!dropdownElement.contains(event.target) &&
				hamburgerElement &&
				!hamburgerElement.contains(event.target)
			) {
				this.dropFunc();
			}
		},
		backgroundStyle(index) {
			const images = [
				require("@/assets/carousel-1.webp"),
				require("@/assets/carousel-2.webp"),
				require("@/assets/carousel-3.webp"),
			];
			return {
				backgroundImage: `url(${images[index - 1]})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: "100%",
				height: "5.4166666667rem",
			};
		},
	},
};
</script>
